<template>
  <modal-layout @close="$emit('close')" :title="$t('docList')">
    <div class="documents-list" v-html="data">

    </div>
    <button class="primary-btn bottom-page-btn" @click="$emit('close')">{{$t('close')}}</button>
  </modal-layout>
</template>

<script>
export default {
  name: "DocumentsListModal",
  props: ['data']
}
</script>

<style lang="scss" scoped>
.documents-list {
  padding: 16px;
  * {
    font-size: 14px;
  }
  .list {
    list-style: disc;
    margin-bottom: 16px;
  }
}
</style>