<template>
  <div>
    <div class="primary-block">
      <h2 class="input-list-title" v-if="title">{{ title }}</h2>
      <div class="input-list-items">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputFieldList",
  props: {
    title: {
      type: String
    }
  }
}
</script>

<style lang="scss">
.form-group {
  .input-list-items {
    .input-field {
      padding: 12px 0;
      border-bottom: 1px solid #C8C7CC;
      margin-bottom: 12px !important;
      &.active {
        .placeholder {
          top: 8px;
        }
        input {
          bottom: 8px;
        }
      }
    }
  }
}
</style>
