<template>
  <modal-layout @close="$emit('close')" :title="$t('carInsurance')">
    <div class="p-3">
      <h3 class="fw-bold">
        {{$t('sosRequestSuccess')}}
      </h3>
    </div>
    <button class="primary-btn bottom-page-btn" @click="closeAndRedirect">{{$t('close')}}</button>
  </modal-layout>
</template>

<script>
export default {
  name: "SosResultModal",
  methods: {
    closeAndRedirect() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>