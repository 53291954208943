<template>
  <secondary-page-layout :title="product && product.title ? product.title: null">
    <ValidationObserver ref="form" v-slot="{ invalid, errors }">
    <div class="form-group pb-80">
      <input-field-list>
        <input-field
            :label="holderName"
            v-model="form.client_iin"
            field-type="tel"
            rules="required|min:12"
            name="holder_iin"
            mask="############"
            @input="(iin)=>getHolder(iin)"
        />
        <div class="addition-invalid" v-if="personInfoError && form.client_full_name.length===12 && !personInfo">
          <icon icon-name="read-info-icon" class="me-2"></icon>
          <span class="">{{ personInfoError.message }}</span>
        </div>
        <div class="d-none">
          <input-field
              v-model="form.product_id"
          />
        </div>
        <input-field
            v-if="product && product.title"
            :label="$t('insuranceProduct')"
            v-model="product.title"
            field-type="text"
            rules="required"
            name="product_type"
            :readonly="true"
        />
        <input-field
            label="Email"
            v-model="form.email"
            field-type="email"
            rules="required|email"
            :requireMark="true"
            name="holder_email"
        />
      </input-field-list>
      <div class="primary-block documents-upload">
        <h5 class="title mb-4">{{$t('attachDocs')}}</h5>
        <h5 class="title mb-3">{{$t('uploadIDCard')}}</h5>
        <span style="color: red">{{ frontIdCardError }}</span>
        <div class="dropzone-previews frontIdCardDropzone"></div>
        <ValidationProvider name="license_front_photo" rules="required">
          <vue-dropzone
              v-model="form.license_front_photo"
              rules="required"
              name="license_front_photo"
              ref="frontIdCardDropzone"
              id="frontIdCardDropzone"
              :options="idCardOptions"
              :duplicateCheck="true"
              :includeStyling="false"
              :useCustomSlot=true
              @vdropzone-thumbnail="thumbnail"
              @vdropzone-success="addFrontIdCard"
              @vdropzone-removed-file="removeFrontIdCard"
              @vdropzone-error="removeFailedFrontIdCard"
              @vdropzone-upload-progress="frontCardDropzoneUploadProgress"
          >
            <div class="d-flex align-items-center mb-4 position-relative">
              <div class="icon-box me-3">
                <icon icon-name="plus-icon"></icon>
              </div>
              <span class="success-text">{{$t('IDCardFront')}}</span>
            </div>
          </vue-dropzone>
        </ValidationProvider>
        <div class="progressBar" :style="{width: frontCardBytes + '%'}" v-if="frontCardBytes !== 0 && frontCardBytes !== 100">
        </div>
        <span style="color:red;">{{ rearIdCardError }}</span>
        <div class="dropzone-previews rearIdCardDropzone"></div>
        <ValidationProvider name="license_rear_photo" rules="required">
          <vue-dropzone
              v-model="form.license_rear_photo"
              name="license_rear_photo"
              rules="required"
              ref="rearIdCardDropzone"
              id="rearIdCardDropzone"
              :options="idCardOptions"
              :duplicateCheck="true"
              :includeStyling="false"
              :useCustomSlot=true
              @vdropzone-thumbnail="thumbnail"
              @vdropzone-success="addRearIdCard"
              @vdropzone-removed-file="removeRearIdCard"
              @vdropzone-error="removeFailedRearIdCard"
              @vdropzone-upload-progress="rearCardDropzoneUploadProgress"
          >
          <div class="d-flex align-items-center mb-4 position-relative">
            <div class="icon-box me-3">
              <icon icon-name="plus-icon"></icon>
            </div>
            <span class="success-text">{{$t('IDCardBack')}}</span>
          </div>
        </vue-dropzone>
        </ValidationProvider>
        <div class="progressBar" :style="{width: rearCardBytes + '%'}" v-if="rearCardBytes !== 0 && rearCardBytes !== 100"></div>
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="title mb-3">{{$t('otherDocs')}}</h5>
          <icon icon-name="read-info-icon" @click.native="openDocumentsModal"></icon>
        </div>
        <span style="color: red">{{ additionalFilesError }}</span>
        <div class="dropzone-previews additionalFilesDropzone"></div>
        <vue-dropzone
          ref="additionalFilesDropzone"
          id="additionalFilesDropzone"
          :options="additionalFilesOptions"
          :includeStyling="false"
          :useCustomSlot=true
          @vdropzone-thumbnail="thumbnail"
          @vdropzone-success="addAdditionalFiles"
          @vdropzone-removed-file="removeAdditionalFilesItem"
          @vdropzone-error="removeFailedAdditionalFiles"
        >
          <div class="d-flex align-items-center mb-4 position-relative">
            <div class="icon-box me-3">
              <icon icon-name="plus-icon"></icon>
            </div>
            <span class="success-text">{{$t('addDocument')}}</span>
          </div>
        </vue-dropzone>
      </div>
    </div>
    <button class="primary-btn bottom-page-btn" @click="submit" :disabled="invalid">Отправить</button>
    </ValidationObserver>
    <v-dialog class="error"/>
  </secondary-page-layout>
</template>

<script>
import DocumentsListModal from "../components/Modals/DocumentsListModal";
import SosResultModal from "../components/Modals/SosResultModal";
import InputField from "../components/BuyPolicy/InputField";
import {mapActions, mapGetters} from "vuex";
import InputFieldList from "../components/BuyPolicy/InputFieldList";
import vue2Dropzone from 'vue2-dropzone'

export default {
  name: "SosForm",
  data() {
    return {
      frontCardBytes: 0,
      rearCardBytes: 0,
      form: {
        client_iin: null,
        client_full_name: null,
        product_id: null,
        email: null,
        license_front_photo: null,
        license_rear_photo: null,
        additional_docs: [],
      },
      product: null,
      client_iin: null,
      idCardOptions: {
        url: `${process.env.VUE_APP_BACKEND_URL}/api/media/upload`,
        addRemoveLinks: true,
        maxFiles: 1,
        acceptedFiles: 'image/jpeg,image/png,image/gif,image/jpg',
        previewsContainer: ".frontIdCardDropzone",
        previewTemplate: this.template(),
        maxFilesize: 10,
        dictFileTooBig: this.$i18n.t('maxDocSize')
      },
      additionalFilesOptions: {
        url: `${process.env.VUE_APP_BACKEND_URL}/api/media/upload`,
        addRemoveLinks: true,
        acceptedFiles: 'image/jpeg,image/png,image/gif,image/jpg,application/pdf,.txt,.docx,.doc',
        previewsContainer: ".additionalFilesDropzone",
        previewTemplate: this.template(),
        maxFilesize: 10,
        dictFileTooBig: this.$i18n.t('maxDocSize'),
        maxFiles: 100
      },
      idCards: [],
      otherFiles: [],
      frontIdCardError: '',
      rearIdCardError: '',
      additionalFilesError: '',
    }
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {InputFieldList, InputField, SosResultModal, DocumentsListModal, vueDropzone: vue2Dropzone},
  computed: {
    ...mapGetters([
      'personInfo',
      'personInfoError',
      'authUser'
    ]),
    holderName() {
      return this.personInfo ? this.personInfo.title : this.$i18n.t('iin');
    },
  },
  created() {
    this.setDefaultInfo();
    this.getInsuranceCaseById();
  },
  methods: {
    ...mapActions(['getPersonInfo']),
    async getHolder(iin) {
      if (iin.length === 12) {
        let loader = this.$loading.show({});
        await this.getPersonInfo(iin);
        this.form.client_full_name = await this.personInfo.title;
        loader.hide();
      }
    },
    setDefaultInfo() {
      if (this.authUser.individual_id_number) {
        this.form.client_iin = this.authUser.individual_id_number;
      }
      if (this.authUser.email) {
        this.form.email = this.authUser.email;
      }
    },
    async getInsuranceCaseById() {
      const { data } = await window.axios.get(`insurance-cases/${this.$route.params.id}`);
      this.product = data;
      this.form.product_id = this.product.id;
    },
    template: function () {
      return `
        <div class="dz-preview dz-file-preview" >
          <div class="dz-main">
            <div class="dz-image">
              <div data-dz-thumbnail-bg class="dz-bg" style="background-image: url('/img/document.svg');"></div>
            </div>
            <div class="dz-details">
              <div class="dz-size"><span data-dz-size></span></div>
              <div class="dz-filename"><span data-dz-name></span></div>
            </div>
          </div>
          <div>
            <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
            <div class="dz-success-mark"><i class="fa fa-check"></i></div>
            <div class="dz-error-mark"><i class="fa fa-close"></i></div>
          </div>
        </div>`
    },
    thumbnail: function(file, dataUrl) {
      let j, len, ref, thumbnailElement;
      if (file.previewElement) {
        file.previewElement.classList.remove("dz-file-preview");
        ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
        }
        return setTimeout(((function(_this) {
          return function() {
            return file.previewElement.classList.add("dz-image-preview");
          };
        })(this)), 1);
      }
    },
    addFrontIdCard(file, response) {
      const loader = this.$loading.show();
      this.form.license_front_photo = response.path;
      this.frontIdCardError = '';
      loader.hide()
    },
    frontCardDropzoneUploadProgress(file, totalBytes, totalBytesSent) {
      console.log(totalBytes)
      this.frontCardBytes = totalBytes;
    },
    rearCardDropzoneUploadProgress(file, totalBytes, totalBytesSent) {
      this.rearCardBytes = totalBytes;
    },
    addRearIdCard(file, response) {
      this.form.license_rear_photo = response.path;
      this.rearIdCardError = '';
    },
    removeFrontIdCard() {
      this.form.license_front_photo = null;
      this.frontCardBytes = 0;
    },
    removeFailedFrontIdCard(file, message) {
      this.$refs.frontIdCardDropzone.removeFile(file);
      this.frontIdCardError = message;
    },
    removeFailedRearIdCard(file, message) {
      this.$refs.rearIdCardDropzone.removeFile(file);
      this.rearIdCardError = message;
    },
    removeFailedAdditionalFiles(file, message) {
      this.$refs.additionalFilesDropzone.removeFile(file);
      this.additionalFilesError = message;
    },
    removeRearIdCard() {
      this.form.license_rear_photo = null;
      this.rearCardBytes = 0;
    },
    addAdditionalFiles(file, response) {
      this.form.additional_docs.push(response.path);
      this.otherFiles.push(file);
      this.additionalFilesError = '';
    },
    removeAdditionalFilesItem(file) {
      let index = this.otherFiles.indexOf(file);
      this.otherFiles.splice(index, 1);
      this.form.additional_docs.splice(index, 1);
    },
    // async uploadFile(file) {
    //   let formData = new FormData();
    //   formData.append('file', file);
    //   // eslint-disable-next-line no-useless-catch
    //     const {data} = await window.axios.post(`media/upload`, formData);
    //     return data.path
    // },
    openDocumentsModal() {
      this.$modal.show(DocumentsListModal, {data: this.product.documentList}, this.$modalConfig)
    },
    redirect() {
      this.$router.push('/');
    },
    async submit() {
      let loader = this.$loading.show({});
      try {
        let success = await this.$refs.form.validate()
        if (success) {
          await window.axios.post('/insurance-cases', this.form);
          this.$modal.show(SosResultModal, {}, this.$modalConfig)
          setTimeout(this.redirect, 400)
        }
        loader.hide()
      } catch(e) {
        loader.hide()
        const {data} = e.response;
        this.$modal.show('dialog', {
          title: 'Ошибка',
          text: data.message,
          buttons: [
            {
              title: 'Закрыть',
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
          ]
        });
        throw e
      }
    }
  },
}
</script>

<style lang="scss">
.pb-80 {
  padding-bottom: 80px;
}
.documents-upload {
  .title {
    font-weight: bold;
    font-size: 14px;
  }
  .preview_wrapper {
    position: relative;
    margin-right: 16px;
  }
  .close_btn {
    position: absolute;
    top: -8px;
    right: -8px;
  }
  .preview {
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }
}
#dropzone {
  margin-bottom: 16px;
}

.dz-preview {
  width: 100%;
  margin-bottom: 18px;
  position: relative;
}
.dz-main {
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 16px;
}
.dz-image {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dz-success-mark {
  display: none;
}
.dz-error-mark {
  display: none;
}
.dz-details {
  .dz-size {
    display: none;
  }
  .dz-filename {
    span {
      color: #2AA65C;
      word-break: break-all;
  }
  }
}
.dz-remove {
  visibility: hidden;
  font-size: 0;
  position: absolute;
  top: -12px;
  left: 36px;
  &:after {
    visibility: visible;
    content: url("/img/close-btn.svg");
}
}
.dz-bg {
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  border-radius: 12px;
}
.dz-max-files-reached {
  .dz-message {
    display: none;
  }
}

.progressBar {
  background: #2aa65c;
  width: 0;
  height: 5px;
  border-radius: 4px;
  margin-bottom: 20px;
}

</style>
